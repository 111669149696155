<template>
  <data-sec />
</template>

<script>
import DataSec from "../../components/solutions/security-solutions/DataSec.vue";
export default {
  components: { DataSec },
  name: "data-security-page",
};
</script>
